/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import {setStormGuardAlert} from 'lib/services/stormGuardAPI'
import {DIALOG_TYPE, PAGE_NAME, STORM_GUARD_STATUS, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {hasEncharge, hasSystemCard, isCountryAU, isCountryUS} from 'lib/utils/siteDetails'
import {getUserId} from 'lib/utils/userDetails'
import {openURL} from 'lib/utils/utility'
import {FEATURE_KEYS, isFeatureSupported} from 'lib/utils/featureKeys'

import {goToPage, isActivePage} from '../bpCommon'

export function isStormAlertActive(details) {
  return details?.stormGuardState === STORM_GUARD_STATUS.ACTIVE
}

// Storm Guard :: Get Toggle Status
export function getStormGuardToggleStatus(stormGuardState, loading) {
  if (loading) {
    return TOGGLE_SWITCH_STATUS.LOADING
  }

  if (stormGuardState === STORM_GUARD_STATUS.ACTIVE || stormGuardState === STORM_GUARD_STATUS.ENABLED) {
    return TOGGLE_SWITCH_STATUS.ON
  }

  return TOGGLE_SWITCH_STATUS.OFF
}

export function getStormGuardState(value) {
  if (value === TOGGLE_SWITCH_STATUS.ON) {
    return STORM_GUARD_STATUS.ENABLED
  }

  return STORM_GUARD_STATUS.DISABLED
}

export function getDescription(stormGuardState = TOGGLE_SWITCH_STATUS.ON) {
  let text
  if (hasSystemCard()) {
    if (hasEncharge()) {
      text = i18next.t('bp209')
    } else {
      text = i18next.t('bp269')
    }
  } else {
    text = stormGuardState === TOGGLE_SWITCH_STATUS.ON ? i18next.t('bp208') : i18next.t('bp482')
  }

  return text
}

export function getInfoForEvDescription() {
  return (
    <div className="sg__popup__wrapper">
      <div className="e_dialog__content">
        {i18next.t('bp414')}
        <br />
        <br />
        {i18next.t('bp415')}
      </div>
    </div>
  )
}

export function getInfoDescription() {
  const url = isCountryAU() ? 'http://www.bom.gov.au/' : 'https://www.spc.noaa.gov'
  const urlText = isCountryAU() ? i18next.t('bp312') : i18next.t('bp92')
  const nws = (
    <a
      role="link"
      className="bp__link"
      onClick={() => openURL(url)}
      onKeyDown={() => {}}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex="0"
    >
      {urlText}
    </a>
  )
  const enabled = <span className="bp__status-text--enabled" />
  const disabled = <span className="bp__status-text--disabled" />
  let desc1 = hasEncharge() ? i18next.t('bp4') : i18next.t('bp271')
  let desc2 = hasEncharge() ? i18next.t('bp5') : i18next.t('bp272')

  if (hasEncharge() && isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) && isCountryUS()) {
    desc1 = i18next.t('bp395')
    desc2 = (
      <Trans i18nKey="bp481">
        When {disabled}, Storm Guard will be enabled automatically only for critical weather alerts.
      </Trans>
    )
  }

  return (
    <div className="sg__popup__wrapper">
      <div className="e_dialog__content">
        {hasEncharge() ? (
          <Trans i18nKey="bp3">
            When {enabled} the Storm Guard feature monitors severe weather condition alerts issued by {nws}. It
            overrides your system profile to Full Backup if an alert is issued for your locality. This allows your
            entire battery capacity to be reserved for use during a potential grid outage.
          </Trans>
        ) : (
          <Trans i18nKey="bp270">
            When {enabled}, the Storm Guard feature monitors severe weather alerts issued by {nws}. When an alert is
            issued for your locality, your EV charges to 100% to be prepared for a potential grid outage.
          </Trans>
        )}
        <br />
        <br />
        {desc1}
        <br />
        <br />
        {desc2}
        {desc2 && <br />}
        {desc2 && <br />}
        {isCountryAU() && (
          <div className="bp_reference_text">
            {i18next.t('bp314')}: {i18next.t('bp313')}
          </div>
        )}
      </div>

      <a
        role="button"
        className="bp__link"
        onClick={() => openURL('https://enphase.com/en-us/support/faq/storm-guard')}
        onKeyDown={() => {}}
        tabIndex="0"
      >
        {i18next.t('bp6')}
        <Icon src="externalLink" />
      </a>
    </div>
  )
}

export function getSgChangeDescription({status, criticalAlertActive = false}) {
  let text = criticalAlertActive ? i18next.t('bp479') : i18next.t('bp11')
  let textConfirm = i18next.t('bp12')

  if (status === TOGGLE_SWITCH_STATUS.ON) {
    text = i18next.t('bp9')
    textConfirm = i18next.t('bp10')
  }

  return (
    <div className="sg__popup__wrapper">
      <div className="e_dialog__content">{text}</div>
      <div className="e_dialog__content">{textConfirm}</div>
    </div>
  )
}

// Storm Alert :: Active
function getStormAlertDescription(details) {
  let desc = (
    <Trans i18nKey="bp13">
      Flood Warning alert is issued for your locality by&nbsp;
      <a href="https://www.spc.noaa.gov/" target="_blank" rel="noopener noreferrer">
        National Weather Service (NWS)
      </a>
      . Please wait for the alert to end (recommended) or disable the Storm Guard feature to proceed with the selection.
    </Trans>
  )

  if (details?.stormAlertMessage) {
    desc = details?.stormAlertMessage
  }

  return (
    <div className="bp__popup__wrapper">
      <Icon src="alert" />
      {typeof desc === 'string' ? (
        <div className="e_dialog__content" dangerouslySetInnerHTML={{__html: desc}} />
      ) : (
        <div className="e_dialog__content">{desc}</div>
      )}
    </div>
  )
}

// Storm Alert :: Popup Details
export const getStormAlertDetails = ({details, closeThisDialog, setBpDetails, setBatteryData}) => {
  const sgInfoDescription = getStormAlertDescription(details)
  const obj = {
    className: 'bp__alert-popup e_dialog--button-w100',
    type: DIALOG_TYPE.CENTER,
    showDialog: true,
    showCloseIcon: false,
    title: null,
    content: sgInfoDescription,
    buttons: null,
  }

  async function setUserAlerted() {
    // Set Storm Guard Alert
    try {
      closeThisDialog()
      if (isActivePage(PAGE_NAME.EDIT_PROFILE)) {
        goToPage({subPage: null, setBpDetails})
      }

      await setStormGuardAlert({userId: getUserId()})
      setBatteryData && setBatteryData({showStormGuardAlert: false})
    } catch (e) {
      console.error('[Error] setUserAlerted', e)
    }
  }

  obj.buttons = [
    {
      value: i18next.t('bp46'),
      action: details?.showStormGuardAlert ? setUserAlerted : closeThisDialog,
      disabled: false,
      className: 'bp__ab--mobile-view-hr',
    },
  ]

  return obj
}

export function hasStormGuardPage() {
  return (
    (isFeatureSupported(FEATURE_KEYS.STORM_GUARD_OPTOUT) || isFeatureSupported(FEATURE_KEYS.STORM_GUARD_EVSE_OPTOUT)) &&
    (isCountryUS() || isCountryAU())
  )
}
