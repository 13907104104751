/* eslint-disable fp/no-mutation */

import React, {memo} from 'react'
import i18next from 'i18next'
import Icon from 'lib/utils/icon'
import classNames from 'classnames'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {navigateToElectricityRate, popupFromIframe} from 'lib/common'
import {DIALOG_TYPE, TOGGLE_SWITCH_STATUS} from 'lib/utils/constants'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'

import {isNem3TariffSupported} from 'lib/utils/siteDetails'
import {TimeKeeper} from '../../atoms/TimeKeeper'
import {
  getTimeErrorDbtg,
  isNEM3SelfConsExpOnlySite,
  noScheduleForNEM3SelfConsExpOnlySite,
  restrictUserToPerformDTGAction,
} from './dbtgCore'
import {setDbtgValue} from './dbtgSlice'

export const DischargeToGridSchedule = memo(function DischargeToGridSchedule(props) {
  const {status, showDialog, closeThisDialog, lockDTGCard} = props

  const isCbfgLoading = useSelector(state => state.components.chargeBatteryFromGrid.loading)
  const {loading, startTime, endTime, showTimeKeeper} = useSelector(state => state.components.dischargeBatteryToGrid)
  const {details} = useSelector(batteryProfileSelector)
  const isBatteryShutdownLoading = useSelector(state => state.components.batteryShutdown.loading)

  const dispatch = useDispatch()
  const setStartTime = obj => dispatch(setDbtgValue({startTime: {...obj}}))
  const setEndTime = obj => dispatch(setDbtgValue({endTime: obj}))
  const setShowTimeKeeper = val => dispatch(setDbtgValue({showTimeKeeper: val}))

  const dischargeStartTime = startTime
  const dischargeEndTime = endTime
  const timeError = !lockDTGCard && getTimeErrorDbtg(dischargeStartTime, dischargeEndTime)

  const handleTimeKeeper = value => {
    setShowTimeKeeper(value)
    popupFromIframe({displayFooter: true, dialogType: DIALOG_TYPE.CENTER})
  }

  const openTimeKeeper = value => {
    if (
      restrictUserToPerformDTGAction({
        details,
        isCbfgLoading,
        loading,
        showDialog,
        closeThisDialog,
        isBatteryShutdownLoading,
        showToastMsg: true,
      })
    )
      return
    handleTimeKeeper(value)
  }

  // Handle Time Change
  const handleTimeChange = (type, obj) => {
    type === 'start' ? setStartTime(obj) : setEndTime(obj)
  }

  // Handle Time Change on Done Button Clicked
  const onDoneButtonClicked = (type, obj) => {
    handleTimeKeeper(false)
    popupFromIframe({displayFooter: true})
    handleTimeChange(type, obj)
  }

  function getTime(value) {
    if (value === 'start') return startTime.formatted12
    if (value === 'end') return endTime.formatted12
    return null
  }

  if (status === TOGGLE_SWITCH_STATUS.OFF) return null

  function renderDbtgSchedule() {
    if (isNEM3SelfConsExpOnlySite(details)) {
      let scheduleContent = null
      const {dtgControl} = details
      if (dtgControl != null) {
        // No Dbtg schedule for today
        const showNoDbtgSchMsg = dtgControl?.enabled && noScheduleForNEM3SelfConsExpOnlySite(details)

        // Dbtg schedule needs to be calculated
        const showDbtgSchLoadingMsg = !dtgControl?.enabled && noScheduleForNEM3SelfConsExpOnlySite(details)

        if (showDbtgSchLoadingMsg) {
          scheduleContent = <span className="dbtg__time-selection__message">{i18next.t('bp454')}</span>
        } else if (showNoDbtgSchMsg) {
          scheduleContent = <span className="dbtg__time-selection__message">{i18next.t('bp455')}</span>
        } else {
          const startTimeLabel = i18next.t('bp456')
          const endTimeLabel = dtgControl?.startTime > dtgControl?.endTime ? i18next.t('bp457') : i18next.t('bp458')
          scheduleContent = (
            <span className="dbtg__time-selection__message">
              {startTimeLabel} <span className="time-label">{startTime.formatted12} </span>
              {endTimeLabel} <span className="time-label"> {endTime.formatted12}</span>
            </span>
          )
        }
      }

      return (
        <div className={classNames('dbtg__time-selection', {'dbtg__time-selection--disabled': lockDTGCard})}>
          <Icon src="clockCircular" className="time-selection" />
          {scheduleContent}
        </div>
      )
    }

    return (
      <div className={classNames('dbtg__time-selection', {'dbtg__time-selection--disabled': lockDTGCard})}>
        <Icon src="clockCircular" />
        <div className="dbtg__time-selection--start">
          <span className="dbtg__time-selection__label">{i18next.t('bp101')}:</span>
          <span
            id="time-selection-start"
            className="dbtg__time-selection__value"
            onClick={() => openTimeKeeper('start')}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            {startTime.formatted12}
          </span>
        </div>

        <div className="dbtg__time-selection--end">
          <span className="dbtg__time-selection__label">{i18next.t('bp102')}:</span>
          <span
            id="time-selection-end"
            className="dbtg__time-selection__value"
            onClick={() => openTimeKeeper('end')}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            {endTime.formatted12}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="dbtg__grid-agnostic">
      <div className="dbtg__ga__wrapper">
        {renderDbtgSchedule()}
        {isNEM3SelfConsExpOnlySite(details) && !isNem3TariffSupported() && (
          <div className="dbtg__nem3-tariff__info">
            <div className="icon">
              <Icon src="infoV3" />
            </div>
            <div className="info-description">
              <span className="desc">{i18next.t('bp459')} </span>
              <span className="desc-link" role="presentation" onClick={() => navigateToElectricityRate()}>
                {i18next.t('bp460')}
              </span>
            </div>
          </div>
        )}
        {timeError && <div className="dbtg__time-error">{timeError}</div>}
      </div>

      {showTimeKeeper && (
        <TimeKeeper
          time={getTime(showTimeKeeper)}
          onChange={obj => handleTimeChange(showTimeKeeper, obj)}
          onDoneClick={obj => onDoneButtonClicked(showTimeKeeper, obj)}
        />
      )}
    </div>
  )
})

DischargeToGridSchedule.propTypes = {
  status: PropTypes.string.isRequired,
  showDialog: PropTypes.func,
  closeThisDialog: PropTypes.func,
  lockDTGCard: PropTypes.bool,
}

DischargeToGridSchedule.defaultProps = {
  showDialog: () => null,
  closeThisDialog: () => null,
  lockDTGCard: false,
}
