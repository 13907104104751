import React, {memo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import classNames from 'classnames'
import {popupFromIframe} from 'lib/common'
import {TOAST_TYPE, TOGGLE_SWITCH_STATUS, DIALOG_TYPE} from 'lib/utils/constants'
import Icon from 'lib/utils/icon'
import {isSiteGridTied} from 'lib/utils/siteDetails'
import Analytics from 'lib/utils/analytics'
import {batteryProfileSelector} from 'lib/store/slices/batteryProfile'
import {TimeKeeper} from '../../atoms/TimeKeeper'
import {isAnyRequestPendingForChargeBatteryFromGrid} from '../../organisms/reqPending'
import {getTimeErrorCbfg, showNewCFG} from './cbfgCore'
import {setCbfgValue} from './cbfgSlice'
import {isStormAlertActive} from '../../organisms/StormGuardDetails/sgCore'
import {showToast} from '../../atoms/Toast'

export const GridAgnostic = memo(function GridAgnostic(props) {
  const {status, lockCFGCard} = props
  const {loading, chargeBeginTime, inProgress, chargeEndTime, chargeFromGridScheduleEnabled, showTimeKeeper} =
    useSelector(state => state.components.chargeBatteryFromGrid)
  const {details} = useSelector(batteryProfileSelector)
  const {forceScheduleSupported} = details.cfgControl

  const isLoading = loading || isAnyRequestPendingForChargeBatteryFromGrid(details) || inProgress

  const dispatch = useDispatch()
  const setScheduleEnabled = val => dispatch(setCbfgValue({chargeFromGridScheduleEnabled: val}))
  const setStartTime = obj => dispatch(setCbfgValue({chargeBeginTime: {...obj}}))
  const setEndTime = obj => dispatch(setCbfgValue({chargeEndTime: obj}))
  const setShowTimeKeeper = val => dispatch(setCbfgValue({showTimeKeeper: val}))

  const startTime = chargeBeginTime
  const endTime = chargeEndTime
  const timeError = getTimeErrorCbfg(startTime, endTime, chargeFromGridScheduleEnabled)

  // On Channge Charge Upto 100 Checkbox
  const onChangeChargeUpto100 = () => {
    if (isStormAlertActive(details)) {
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp244'),
        autoClose: 3000,
      })
      return
    }

    if (!isLoading) {
      setScheduleEnabled(!chargeFromGridScheduleEnabled)
    }
    Analytics.sendClickEvent('CFG_Set_Schedule_Checked', 'Battery_Settings')
  }

  // Handle Time Change on Done Button Clicked
  const onDoneButtonClicked = (type, obj) => {
    if (type === 'start') Analytics.sendClickEvent(`CFG_Starting_Hour_${obj && obj.formatted12}`, 'Battery_Settings')
    else Analytics.sendClickEvent(`CFG_Ending_Hour_${obj && obj.formatted12}`, 'Battery_Settings')
    openTimeKeeper(false)
    popupFromIframe({displayFooter: true})
    handleTimeChange(type, obj)
  }

  // Handle Time Change
  const handleTimeChange = (type, obj) => {
    type === 'start' ? setStartTime(obj) : setEndTime(obj)
  }

  // Open Time Keeper
  const openTimeKeeper = value => {
    if (!isLoading) {
      setShowTimeKeeper(value)
      popupFromIframe({displayFooter: true, dialogType: DIALOG_TYPE.CENTER})
    }
  }

  function getTime(value) {
    if (value === 'start') {
      return startTime.formatted12
    }

    if (value === 'end') {
      return endTime.formatted12
    }

    return null
  }

  // old Charge from grid UI
  function renderOldChargeFromGrid() {
    return (
      <>
        <div className="cbfg__ga__below-reserve bp__radio-checkbox__button">
          <label htmlFor="ga-below-reserve-checkbox">
            <input type="checkbox" id="ga-below-reserve-checkbox" defaultChecked disabled />
            <div className="cbfg__ga__below-reserve-text">{i18next.t('bp242')}</div>
          </label>
        </div>

        <div className="cbfg__ga__100 bp__radio-checkbox__button">
          <label htmlFor="ga-100-checkbox">
            <input
              type="checkbox"
              id="ga-100-checkbox"
              checked={chargeFromGridScheduleEnabled}
              disabled={isLoading}
              onChange={() => onChangeChargeUpto100()}
            />
            <div className="cbfg__ga__100-text">{i18next.t('bp243')}</div>
          </label>
        </div>
      </>
    )
  }

  // useEffect on mount
  useEffect(() => {
    const {cb} = props
    cb({startTime, endTime})
  }, [startTime, endTime])

  if (status === TOGGLE_SWITCH_STATUS.OFF) {
    return null
  }

  return (
    <div
      className={`cbfg__grid-agnostic ${
        (!showNewCFG() && !isSiteGridTied()) || forceScheduleSupported ? 'oldcbfg__oldgrid-agnostic' : ''
      } ${classNames({'cbfg__schedule-selection--disabled': lockCFGCard})}`}
    >
      <div className="cbfg__ga__wrapper">
        {((!showNewCFG() && !isSiteGridTied()) || forceScheduleSupported) && renderOldChargeFromGrid()}

        {/* <div className="cbfg__ga__below-reserve bp__radio-checkbox__button">
          <label htmlFor="ga-below-reserve-checkbox">
            <input type="checkbox" id="ga-below-reserve-checkbox" defaultChecked />
            Any time the battery is below reserve.
          </label>
        </div> */}

        {/* <div className="cbfg__ga__100 bp__radio-checkbox__button">
          <label htmlFor="ga-100-checkbox">
            <input
              type="checkbox"
              id="ga-100-checkbox"
              defaultChecked={chargeFromGridScheduleEnabled
}
              disabled={isLoading}
              onChange={() => onChangeChargeUpto100()}
            />
            {i18next.t('bp74')}
          </label>
        </div> */}

        <div
          className={classNames('cbfg__time-selection', {
            'cbfg__time-selection--disabled':
              !showNewCFG() && !isSiteGridTied() ? !chargeFromGridScheduleEnabled || isLoading : isLoading,
          })}
        >
          <Icon src="clockCircular" />

          <div className="cbfg__time-selection--start">
            <span className="cbfg__time-selection__label">{i18next.t('bp101')}:</span>
            <span
              id="time-selection-start"
              className="cbfg__time-selection__value"
              onClick={() => openTimeKeeper('start')}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              {startTime.formatted12}
            </span>
          </div>

          <div className="cbfg__time-selection--end">
            <span className="cbfg__time-selection__label">{i18next.t('bp102')}:</span>
            <span
              id="time-selection-end"
              className="cbfg__time-selection__value"
              onClick={() => openTimeKeeper('end')}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
            >
              {endTime.formatted12}
            </span>
          </div>
        </div>

        {timeError && <div className="cbfg__time-error">{timeError}</div>}
      </div>

      {showTimeKeeper && (
        <TimeKeeper
          time={getTime(showTimeKeeper)}
          onChange={obj => handleTimeChange(showTimeKeeper, obj)}
          onDoneClick={obj => onDoneButtonClicked(showTimeKeeper, obj)}
        />
      )}
    </div>
  )
})

GridAgnostic.propTypes = {
  status: PropTypes.string.isRequired,
  details: PropTypes.shape({}).isRequired,
  cb: PropTypes.func.isRequired,
  lockCFGCard: PropTypes.bool,
}

GridAgnostic.defaultProps = {
  lockCFGCard: false,
}
