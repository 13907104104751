/* eslint-disable fp/no-mutation */
import React from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'
import EnergyType from '../../atoms/EnergyType'
import {getFixedValue} from '../../../../utils/utility'
import {isEnsembleSite, siteHasPv, siteHasCt} from '../../../../utils/siteDetails'

import Icon from '../../../../utils/icon'
import './styles.scss'

class LiveStatusWithArrows extends React.Component {
  constructor() {
    super()
    this.initAllArrowIcons()
  }

  getSolarLabel = () => {
    let solarLabel = i18next.t('bp178')

    if (siteHasPv) {
      solarLabel = i18next.t('bp179')
    }

    return solarLabel
  }

  getGridLabel = (isGridConnected, imported, exported, ojasStatus) => {
    let gridLabel = ojasStatus ? i18next.t('bp180') : i18next.t('bp181')

    if (ojasStatus) {
      if (imported > 0) {
        gridLabel = i18next.t('bp182')
      } else {
        gridLabel = i18next.t('bp183')
      }
    } else if (isEnsembleSite()) {
      if (!isGridConnected) {
        gridLabel = i18next.t('bp184')
      } else if (imported > 0) {
        gridLabel = i18next.t('bp182')
      } else if (exported > 0) {
        gridLabel = i18next.t('bp143')
      } else {
        gridLabel = i18next.t('bp183')
      }
    } else if (!isGridConnected) {
      gridLabel = i18next.t('bp184')
    } else if (imported === 0 && exported === 0) {
      gridLabel = i18next.t('bp183')
    } else if (imported > 0) {
      gridLabel = i18next.t('bp182')
    } else {
      gridLabel = i18next.t('bp143')
    }

    return gridLabel
  }

  getGeneratorLabel = isGenConnected => {
    let generatorLabel = i18next.t('bp185')

    if (!isGenConnected) {
      generatorLabel = i18next.t('bp184')
    }

    return generatorLabel
  }

  getHomeLabel = () => {
    let homeLabel = i18next.t('bp186')

    if (!siteHasCt) {
      homeLabel = i18next.t('bp187')
    } else {
      homeLabel = i18next.t('bp142')
    }

    return homeLabel
  }

  getBatteryLabel = (charge, discharge) => {
    let batteryLabel = i18next.t('bp188')

    if (charge === 0 && discharge === 0) {
      batteryLabel = i18next.t('bp183')
    } else if (charge > 0) {
      batteryLabel = i18next.t('bp189')
    } else {
      batteryLabel = i18next.t('bp144')
    }

    return batteryLabel
  }

  getDisplayValue(value) {
    const val = getFixedValue(Math.abs(value), 1)
    return val
  }

  initAllArrowIcons() {
    this.arrowIcons = {
      solar: {
        icon: 'energySolarCircle',
        toHomeArrowIcon: 'energySolarToHomeLmab', // 'energySolarToHome',
        toBatteryArrowIcon: 'energySolarToBatteryLmab',
        toGridArrowIcon: 'energySolarToGridLmab', // 'energySolarToGrid',
      },
      home: {
        icon: 'energyHomeCircle',
      },
      grid: {
        icon: 'energyGridCircle',
        toHomeConnectedArrowIcon: 'energyGridToHomeConnectedLmab', // 'energyGridToHomeConnected',
        toHomeDisconnectedArrowIcon: 'energyGridToHomeDisconnected',
        toBatteryArrowIcon: 'energyGridToBattery',
      },
      generator: {
        icon: 'energyGeneratorCircle',
        toHomeArrowIcon: 'energyGeneratorToHome', // app.state.get('showBatteryConfig')
        toBatteryArrowIcon: 'energyGeneratorToBattery',
      },
      battery: {
        icon: 'energyBatteryCircle',
        toHomeArrowIcon: 'energyBatteryToHomeLmab', // 'energyBatteryToHome',
        toGridArrowIcon: 'energyBatteryToGrid',
      },
    }
  }

  renderGridToBatteryOrBatteryToGrid() {
    const props = JSON.parse(this.props.lsData)
    if (props.grid.toBattery) {
      return <Icon src={this.arrowIcons.grid.toBatteryArrowIcon} className={props.className} />
    }
    if (props.battery.toGrid) {
      return <Icon src={this.arrowIcons.battery.toGridArrowIcon} className={props.className} />
    }
    return (
      props.generator.toBattery && (
        <Icon src={this.arrowIcons.generator.toBatteryArrowIcon} className={props.className} />
      )
    )
  }

  render() {
    const {className, solar, home, grid, generator, battery, isGridConnected, isGenConnected} = JSON.parse(
      this.props.lsData
    )
    const imported = grid.rowValue > 0 ? Math.abs(grid.rowValue) : 0 // +ve
    const exported = grid.rowValue < 0 ? Math.abs(grid.rowValue) : 0 // -ve
    const charge = battery.rowValue < 0 ? Math.abs(battery.rowValue) : 0 // -ve
    const discharge = battery.rowValue > 0 ? Math.abs(battery.rowValue) : 0 // +ve

    return (
      <div className="live-status__energy-flow">
        <div className="live-status__energy-flow__wrapper">
          <div className="live-status__energy-flow__wrapper__inner-wrapper">
            <div className="live-status__energy-flow__wrapper__inner-wrapper__top">
              {!_.isEmpty(solar) && (
                <EnergyType
                  className="produced"
                  icon={solar.icon ? solar.icon : this.arrowIcons.solar.icon}
                  text={solar.label ? solar.label : this.getSolarLabel()}
                  loadingValue={null}
                  value={this.getDisplayValue(solar.rowValue)}
                  unit={solar.rowValue !== 0 ? solar.unit : ''}
                />
              )}
            </div>

            <div className="live-status__energy-flow__wrapper__inner-wrapper__center">
              {!_.isEmpty(grid) && (
                <div
                  className={`live-status__energy-flow__wrapper__inner-wrapper__center__system-grid-status ${
                    !isGridConnected ? 'disconnected' : ''
                  }`}
                >
                  <EnergyType
                    className="imported"
                    icon={grid.icon ? grid.icon : this.arrowIcons.grid.icon}
                    text={grid.label ? grid.label : this.getGridLabel(isGridConnected, imported, exported)}
                    loadingValue={null}
                    value={this.getDisplayValue(grid.rowValue)}
                    unit={grid.rowValue !== 0 ? grid.unit : ''}
                  />
                </div>
              )}

              {!_.isEmpty(generator) && (
                <div
                  className={`live-status__energy-flow__wrapper__inner-wrapper__center__generator ${
                    !isGenConnected ? 'disconnected' : ''
                  }`}
                >
                  <EnergyType
                    className="generator"
                    icon={generator.icon ? generator.icon : this.arrowIcons.generator.icon}
                    text={generator.label ? generator.label : this.getGeneratorLabel()}
                    loadingValue={null}
                    value={this.getDisplayValue(generator.rowValue)}
                    unit={generator.rowValue !== 0 ? generator.unit : ''}
                  />
                </div>
              )}

              <div className="live-status__energy-flow__wrapper__inner-wrapper__center__arrows">
                <div className="live-status__energy-flow__wrapper__inner-wrapper__center__arrows__arrows-top">
                  <div>
                    {solar.toGrid && <Icon src={this.arrowIcons.solar.toGridArrowIcon} className={className} />}
                  </div>
                  <div>
                    {solar.toHome && <Icon src={this.arrowIcons.solar.toHomeArrowIcon} className={className} />}
                  </div>
                </div>
                <div className="live-status__energy-flow__wrapper__inner-wrapper__center__arrows__arrows-bottom">
                  <div>{this.renderGridToBatteryOrBatteryToGrid()}</div>
                  <div>
                    {!_.isEmpty(generator)
                      ? generator.toHome && (
                          <Icon src={this.arrowIcons.generator.toHomeArrowIcon} className={className} />
                        )
                      : battery.toHome && <Icon src={this.arrowIcons.battery.toHomeArrowIcon} className={className} />}
                  </div>
                </div>

                <div className="live-status__energy-flow__wrapper__inner-wrapper__center__arrows__arrows-horizontal">
                  <div>
                    {
                      isGridConnected
                        ? grid.toHome && (
                            <Icon src={this.arrowIcons.grid.toHomeConnectedArrowIcon} className={className} />
                          )
                        : this.isSiteHasGenerator &&
                          generator.toHome && (
                            <Icon src={this.arrowIcons.generator.toHomeArrowIcon} className={className} />
                          )
                      // : <Icon type={this.arrowIcons.grid.toHomeDisconnectedArrowIcon} />
                    }
                  </div>
                </div>
                <div className="live-status__energy-flow__wrapper__inner-wrapper__center__arrows__arrows-vertical">
                  <div>
                    {solar.toBattery && <Icon src={this.arrowIcons.solar.toBatteryArrowIcon} className={className} />}
                  </div>
                </div>
              </div>

              {!_.isEmpty(home) && (
                <div className="live-status__energy-flow__wrapper__inner-wrapper__center__consumed">
                  <EnergyType
                    className="consumed"
                    icon={home.icon ? home.icon : this.arrowIcons.home.icon}
                    text={home.label ? home.label : this.getHomeLabel()}
                    loadingValue={null}
                    value={this.getDisplayValue(home.rowValue)}
                    unit={home.rowValue !== 0 ? home.unit : ''}
                  />
                </div>
              )}
            </div>

            <div className="live-status__energy-flow__wrapper__inner-wrapper__bottom">
              {!_.isEmpty(battery) && (
                <EnergyType
                  className="discharged"
                  icon={battery.icon ? battery.icon : this.arrowIcons.battery.icon}
                  text={battery.label ? battery.label : this.getBatteryLabel(charge, discharge)}
                  loadingValue={null}
                  value={this.getDisplayValue(battery.rowValue)}
                  unit={battery.rowValue !== 0 ? battery.unit : ''}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LiveStatusWithArrows.propTypes = {
  lsData: PropTypes.string,
}

LiveStatusWithArrows.defaultProps = {
  lsData: '',
}

export default LiveStatusWithArrows
