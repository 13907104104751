import React from 'react'

const loaderV4 = () => (
  <svg className="loaderV4" width="60px" height="60px" viewBox="0 0 80 80">
    <circle
      fill="none"
      cx="40"
      cy="40"
      r="20"
      stroke="#01B5DE"
      strokeDasharray="95 84"
      strokeWidth="3px"
      strokeLinecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 40 40"
        to="360 40 40"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

export default loaderV4
