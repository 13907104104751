/* eslint-disable fp/no-mutation */
import React from 'react'
import {Trans} from 'react-i18next'
import i18next from 'i18next'
import {getBatteryReserve} from 'lib/store'
import {ACTION_TYPE, PENDING_REQ_KEY, PROFILE_STATUS, PROFILE_TYPE} from 'lib/utils/constants'
import {isAnyRequestPendingForBatteryReserve, isBatteryBackupPercentageRequestPending} from '../../organisms/reqPending'

// Battery Reserve :: Card Details
export function getCardDetails(details) {
  const isAnyReqPending = isAnyRequestPendingForBatteryReserve(details)
  const isMqttReqPending = isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)
  const isReqPending = isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)

  if (details && !isAnyReqPending) {
    return {
      type: PROFILE_STATUS.ACTIVE,
      typeText: 'Active',
      buttonLabel: i18next.t('bp48'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isMqttReqPending) {
    return {
      type: PROFILE_STATUS.PROCESSING,
      typeText: null,
      buttonLabel: i18next.t('bp48'),
      buttonActionType: ACTION_TYPE.SAVE,
    }
  }

  if (isReqPending) {
    return {
      type: PROFILE_STATUS.PENDING,
      typeText: i18next.t('bp100'),
      buttonLabel: i18next.t('bp49'),
      buttonActionType: ACTION_TYPE.CANCEL,
    }
  }

  return {
    buttonLabel: i18next.t('bp48'),
    buttonActionType: ACTION_TYPE.SAVE,
  }
}

// Battery Reserve :: Get Value
export function getBatteryReserveValue({profile, details}) {
  const batteryReserve = getBatteryReserve()
  const reqConfigMqtt = details[PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT]
  const reqConfig = details[PENDING_REQ_KEY.REQUESTED_CONFIG]
  const isMqttReqPending = isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG_MQTT)
  const isReqPending = isBatteryBackupPercentageRequestPending(details, PENDING_REQ_KEY.REQUESTED_CONFIG)
  let reserve = details?.batteryBackupPercentage

  if (profile && profile !== details.profile) {
    reserve = getPreviousBatteryReserveValue(details, profile)
  }

  if (profile && batteryReserve?.reserveProfile[profile]) {
    reserve = batteryReserve?.reserveProfile[profile]
  }

  if (reqConfigMqtt.profile === profile && isMqttReqPending) {
    reserve = details && reqConfigMqtt.batteryBackupPercentage
  }

  if (reqConfig.profile === profile && isReqPending) {
    reserve = details && reqConfig.batteryBackupPercentage
  }

  return reserve
}

// Battery Reserve :: Get Previous Value
export function getPreviousBatteryReserveValue(details, profile) {
  if (profile) {
    return details.previousBatteryBackupPercentage && details.previousBatteryBackupPercentage[profile]
  }

  return details.previousBatteryBackupPercentage && details.previousBatteryBackupPercentage[details.profile]
}

export function getLastSetReserveObj({details, batteryReserve, profile}) {
  const brObj = {}
  let reserve = details.batteryBackupPercentage

  if (details.profile !== profile) {
    reserve = getPreviousBatteryReserveValue(details, profile)
  }

  if (batteryReserve.reserveProfile && batteryReserve.reserveProfile[PROFILE_TYPE.SELF_CONSUMPTION]) {
    brObj.brReserve = batteryReserve.reserveProfile[PROFILE_TYPE.SELF_CONSUMPTION]
    brObj.reserveProfile = {}
    brObj.reserveProfile[PROFILE_TYPE.SELF_CONSUMPTION] = batteryReserve.reserveProfile[PROFILE_TYPE.SELF_CONSUMPTION]
  } else {
    brObj.brReserve = reserve
    brObj.reserveProfile = {}
    brObj.reserveProfile[PROFILE_TYPE.SELF_CONSUMPTION] = brObj.brReserve
  }
  return brObj
}

// Battery Reserve :: has value changed
export function hasBatteryReserveValueChanged(details, profile) {
  if (profile === details.profile) {
    return getBatteryReserveValue({profile, details}) !== details?.batteryBackupPercentage
  }

  return getBatteryReserveValue({profile, details}) !== getPreviousBatteryReserveValue(details, profile)
}

// Battery Reserve :: Get Description
export function getBatteryReserveDescription({value}) {
  if (value) {
    return (
      <Trans i18nKey="bp56">
        <span className="bp__card-view--value">{{value}}</span> of the battery charge would be reserved to power your
        home during outages.
      </Trans>
    )
  }

  return null
}

// Battery Reserve :: Get VLS Description
export function getVLSDescription(value) {
  let text = ''
  const redirection = (
    <a
      role="link"
      className="bp__link"
      onClick={() => {
        const updatedUrlParams = new URLSearchParams(window.location.search)
        updatedUrlParams.set('type', 'battery')
        const newUrl = `${window.location.pathname}?${updatedUrlParams.toString()}`
        window.location.href = newUrl
      }}
      onKeyDown={() => {}}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex="0"
    >
      Battery Settings
    </a>
  )

  if (value) {
    text = (
      <Trans i18nKey="bp212">
        Reserve cannot be set lower than battery shutdown level <span className="bp__card-view--value">{{value}}</span>.
        Please decrease your shutdown level from {redirection} to further decrease the reserve.
      </Trans>
    )
  }
  return text
}

// Battery Reserve :: Get Range Error Text
export function getRangeErrorText(value, veryLowSoc, min) {
  if (value <= min) {
    return <span className="rs__text--error">{i18next.t('bp58', {value})}</span>
  }

  if (value <= veryLowSoc) {
    return <span className="rs__text--error">{getVLSDescription(value)}</span>
  }

  return null
}
