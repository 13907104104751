import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import {navigateBack} from 'lib/common'
import {batteryProfileSelector, setBpValue} from 'lib/store/slices/batteryProfile'
import {closeDialog, setDialogValue} from 'lib/store/slices/dialogDetails'
import {PAGE_NAME} from 'lib/utils/constants'
import {isEnhoWeb} from 'lib/utils/utility'
import Analytics from 'lib/utils/analytics'
import Icon from '../../../../utils/icon'
import {getProfileCurrentDetails} from '../../organisms/ProfileDetails/pdCore'
import {getDevices} from '../EvTopUpSchedule/evScheduleCore'
import {getDiscardPopupDetails, isInfoChanged} from '../../organisms/bpCommon'
import {removeDevice} from '../../molecules/DeviceBehaviour/dbSlice'
import './styles.scss'

/* *********************** *********************** *********************** *
 * Header
 * *********************** *********************** *********************** */

const Header = props => {
  const {id, title, subTitle, onClickAction, actionTitle} = props
  const batteryProfile = useSelector(batteryProfileSelector)

  const dispatch = useDispatch()
  const setBpDetails = obj => dispatch(setBpValue({...obj}))
  const closeThisDialog = () => dispatch(closeDialog())
  const showDialog = obj => dispatch(setDialogValue({...obj}))
  const removeDeviceUpdate = obj => dispatch(removeDevice(obj))
  const loader = useSelector(state => state.components.loader)
  const profileDetails = useSelector(state => state.components.profileDetails)
  const {deviceChanges} = useSelector(state => state.components.deviceUpdate[profileDetails.openCard]) || []
  const {details} = useSelector(batteryProfileSelector)
  const devices = getDevices(details)
  const spDetails = getProfileCurrentDetails(
    details,
    details.profile,
    profileDetails.inProgress,
    profileDetails.requestType
  )
  const externalPages = [PAGE_NAME.PROFILE, PAGE_NAME.BATTERY, PAGE_NAME.STORM_GUARD]

  /* *********************** *********************** *********************** *
   * Arrow Functions
   * *********************** *********************** *********************** */

  const onClickBack = () => {
    const pagesHasURL = [PAGE_NAME.PROFILE, PAGE_NAME.BATTERY, PAGE_NAME.STORM_GUARD]

    if (id === PAGE_NAME.EDIT_PROFILE) Analytics.sendClickEvent('Back_edit_profile', 'edit_profile')

    if (_.includes(externalPages, id) && batteryProfile.subPage.length === 0) {
      navigateBack()
    } else if (!loader.show) {
      const subPageList = [...batteryProfile.subPage]
      if (
        batteryProfile.subPage.includes(PAGE_NAME.EDIT_PROFILE) &&
        isInfoChanged(profileDetails, deviceChanges, details, devices, spDetails)
      ) {
        const obj = getDiscardPopupDetails(
          setBpDetails,
          closeThisDialog,
          deviceChanges,
          devices,
          removeDeviceUpdate,
          profileDetails.openCard
        )
        showDialog(obj)
        return
      }
      _.remove(subPageList, val => val === id)

      if (subPageList?.length === 1 && _.includes(pagesHasURL, _.first(subPageList))) {
        changeUrl(_.first(subPageList))
      } else {
        setBpDetails({subPage: subPageList})
      }
    }
  }

  // Change browser URL
  const changeUrl = type => {
    const url = window.location.href
    window.location.assign(url.replace(/(type=).*?(&)/, `type=${type}&`))
  }

  const showBackButton = () => !isEnhoWeb() // || !_.includes(externalPages, id)

  /* *********************** *********************** *********************** *
   * Render
   * *********************** *********************** *********************** */

  return (
    <header>
      <div className="bp__header--wrapper atom-design--t3">
        {showBackButton() && (
          <div className="com__ad__icon" onClick={onClickBack} onKeyDown={() => {}} role="button" tabIndex="0">
            <Icon src="prevHeader" />
          </div>
        )}

        <div
          className={classNames('com__ad__details', {
            'com__ad--with-subtitle': subTitle,
          })}
        >
          <div className="com__ad--title">{title}</div>
          {subTitle && <div className="com__ad--sub-title">{subTitle}</div>}
        </div>

        {actionTitle && (
          <div className="com__ad__action" onClick={onClickAction} onKeyDown={() => {}} role="button" tabIndex="0">
            {actionTitle}
          </div>
        )}
      </div>
    </header>
  )
}

Header.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actionTitle: PropTypes.element,
  onClickAction: PropTypes.func,
}

Header.defaultProps = {
  title: null,
  subTitle: null,
  actionTitle: null,
  onClickAction: null,
}

export default Header
