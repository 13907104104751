import React from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import ReactTimeKeeper from 'react-timekeeper'
import './styles.scss'

/*
  Ref: https://catc.github.io/react-timekeeper/#examples
  Git: https://github.com/catc/react-timekeeper
  Issue: https://github.com/catc/react-timekeeper/issues/115
  
  disabledTimeRange={{from: '6:30', to: '8:40'}}
*/

export const TimeKeeper = props => {
  const {onDoneClick, onChange} = props

  const onDoneButtonClick = obj => {
    onDoneClick(obj)
  }

  const onTimeChange = obj => {
    onChange(obj)
  }

  const renderDoneButton = obj => {
    const doneText = i18next.t('bp103')
    return (
      <div
        className="react-timekeeper__done-btn"
        onClick={() => onDoneButtonClick(obj)}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      >
        {doneText}
      </div>
    )
  }

  return (
    <div className="bp__time-keeper">
      <ReactTimeKeeper
        coarseMinutes
        forceCoarseMinutes
        switchToMinuteOnHourSelect
        closeOnMinuteSelect={false}
        doneButton={renderDoneButton}
        {...props}
        onDoneClick={onDoneButtonClick}
        onChange={onTimeChange}
      />
    </div>
  )
}

TimeKeeper.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func,
}

TimeKeeper.defaultProps = {
  onDoneClick: null,
}
